<template>
  <component :is="name" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  components: {
    arm: require('@/assets/icons/arm.svg'),
    arrow: require('@/assets/icons/arrow.svg'),
    draggable: require('@/assets/icons/draggable.svg'),
    plus: require('@/assets/icons/plus.svg'),
    minus: require('@/assets/icons/minus.svg'),
    personaRepairing: require('@/assets/icons/personaRepairing.svg'),
    delivery: require('@/assets/icons/delivery.svg'),
    sommelier: require('@/assets/icons/sommelier.svg'),
    whatsApp: require('@/assets/icons/whatsApp.svg'),
    whatsAppLogo: require('@/assets/icons/whatsAppSmall.svg'),
    play: require('@/assets/icons/startPlay.svg'),
    dispatcher: require('@/assets/icons/dispatcher.svg'),
    fastDelivery: require('@/assets/icons/fastDelivery.svg'),
    payment: require('@/assets/icons/payment.svg'),
    clock: require('@/assets/icons/clock.svg'),
    truck: require('@/assets/icons/truck.svg'),
    question: require('@/assets/icons/question.svg'),
    subtract: require('@/assets/icons/subtract.svg'),
    check: require('@/assets/icons/check.svg'),
    fileDownload: require('@/assets/icons/file-download.svg'),
    rub: require('@/assets/icons/rub.svg'),
    instagram: require('@/assets/icons/instagram.svg'),
    facebook: require('@/assets/icons/facebook.svg'),
    youtube: require('@/assets/icons/youTube.svg'),
    trash: require('@/assets/icons/trash.svg'),
    filters: require('@/assets/icons/filters.svg'),
    telegram: require('@/assets/icons/telegram.svg'),
    save: require('@/assets/icons/save.svg'),
    eye: require('@/assets/icons/eye.svg'),
    close: require('@/assets/icons/close.svg'),
    edit: require('@/assets/icons/edit.svg'),
    telegramArrow: require('@/assets/icons/telegram-arrow.svg'),
    deliveryCar: require('@/assets/icons/delivery-car.svg'),
    certificate: require('@/assets/icons/advantages/certificate.svg'),
    consultant: require('@/assets/icons/advantages/consultant.svg'),
    official: require('@/assets/icons/advantages/official.svg'),
    quality: require('@/assets/icons/advantages/quality.svg'),
    advantagePerson: require('@/assets/icons/advantage-person.svg'),
    advantageAlarm: require('@/assets/icons/advantage-alarm.svg'),
    advantageDelivery: require('@/assets/icons/advantage-delivery.svg'),
    advantageDiamond: require('@/assets/icons/advantage-diamond.svg'),
  },
  props: {
    name: String,
  },
}
</script>
